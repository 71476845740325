* {
  margin: 0;
  padding: 0;

  box-sizing: border-box;
}
.home-wrapper {
  text-align: center;
}
.home-title {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 55px;
  line-height: 75px;
  text-align: center;
  margin: 10px 0;
  color: #3c6ed7;
}
.home-description {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 43px;
  text-align: center;
  letter-spacing: 0.01em;
  margin-bottom: 30px;
  color: #313640;
}
.home-image img {
  width: 460px;
  padding: 15px 0;
}
