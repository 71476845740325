* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.sign-in-icon img {
  margin: 20px 0;
}
.sign-in-wrapper {
  background-color: #f2f2f2;
  text-align: center;
  margin: 15px auto 0;
  width: 600px;
  border-radius: 15px;
  margin-top: 80px;
}
.email {
  width: 445px;
  height: 60px;
  border-radius: 12px;
  margin-bottom: 15px;
  border: none;
}
.password {
  width: 445px;
  height: 60px;
  border-radius: 12px;
  margin-bottom: 15px;
  border: none;
}

.sign-in-button button {
  width: 346px;
  height: 70px;
  background-color: #3c6ed7;
  font-weight: 700;
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  text-align: center;
  color: #fffbf7e3;
  border-radius: 26px;
  margin-bottom: 15px;
  border: none;
}
.sign-in-txt p {
  padding-bottom: 15px;
  font-family: "Roboto", sans-serif;
}
::placeholder {
  color: #555555;
  padding-left: 20px;
  font-size: 15px;
  font-weight: 300;
  font-family: "Roboto", sans-serif;
}
