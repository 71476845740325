* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

nav {
  /* position: fixed; */
  top: 0;
  width: 100%;
  background-color: #3c6ed7;
  display: flex;
  justify-content: flex-end;
}
.logo-wrapper {
  margin-right: auto;
  margin-top: 20px;
  margin-bottom: 18px;
  margin-left: 70px;
}
.link-wrapper {
  display: flex;
  align-items: center;

  width: 887px;
}
.link-wrapper ul {
  display: flex;
  justify-content: space-between;
  width: 887px;
  margin-right: 135px;
}
.link-wrapper ul li {
  list-style: none;
  font-family: "Be Vietnam Pro";
  font-size: 20px;
  font-weight: 600;
}
.link-wrapper ul li a {
  color: #fffbf7;
  text-decoration: none;
}
.activeItem {
  color: #ffde44;
}
