* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.about-us {
  display: flex;

  padding: 0 76px;
}
.title {
  font-weight: 700;
  font-size: 48px;
  color: #3c6ed7;
  font-family: "Roboto", sans-serif;
}
.left {
  margin: auto 90px;
}
.right {
  margin-left: auto;
}
.right img {
  padding-right: 70px;
}
.description p {
  font-weight: 500;
  font-size: 28px;
  font-family: "Outfit", sans-serif;
}
