* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.our-product-wrapper {
  text-align: center;
  margin-top: 60px;
}
.our-products {
  font-size: 50px;
  font-weight: 700;
  font-family: "Roboto", sans-serif;
  color: #3c6ed7;
  margin-bottom: 20px;
}
.discription {
  font-size: 30px;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
}
.simple {
  margin-top: 20px;
  font-size: 35px;
  color: #3c6ed7;
  font-weight: 900;
  font-family: "Roboto", sans-serif;
}
.products {
  display: flex;
  justify-content: space-around;
  margin-top: 25px;
}
.bottom-products {
  margin-bottom: 20px;
}
.left-product,
.right-product {
  text-align: center;
  background-color: #f2f2f2;
  border-radius: 20px;
  width: 580px;
  height: 460px;
}
.left-product-type {
  margin-left: 200px;
}
.right-product-type {
  margin-right: 200px;
}
.product-title {
  color: #3c6ed7;
  font-weight: 700;
  font-size: 32px;
  font-family: "Roboto", sans-serif;
}
.product-description {
  font-weight: 300;
  margin: 10px 0;
  font-size: 20px;
  font-family: "Roboto", sans-serif;
}
