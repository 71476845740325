* {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  box-sizing: border-box;
}
.contact-wrapper {
  background-color: #f2f2f2;
  text-align: center;
  width: 700px;
  margin: 15px auto 25px;
  margin-top: 80px;
}
.contact-us p {
  color: #3c6ed7;
  font-weight: 700;
  font-size: 36px;
  padding: 20px 0;
}
.contact-us-description {
  font-size: 23px;
  font-weight: 400;
  padding: 0 0 20px 0;
}
.input {
  width: 550px;
  height: 90px;
  border-radius: 20px;
  border: none;
}
.Name {
  margin-bottom: 20px;
}
.Email {
  margin-bottom: 20px;
}
textarea {
  width: 550px;
  height: 230px;
  border-radius: 20px;
  border: none;
  margin-bottom: 20px;

  resize: none;
}
::placeholder {
  color: #555555;
  padding-left: 20px;
  font-size: 15px;
  font-weight: 300;
  font-family: "Roboto", sans-serif;
}
.submit {
  width: 335px;
  height: 90px;
  background-color: #3c6ed7;
  border-radius: 10px;
  border: none;
  margin-bottom: 23px;
  color: #fffbf7;
  font-size: 24px;
}
.sign-in-txt p {
  padding-bottom: 25px;
}
